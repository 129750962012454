import React from "react"

import Layout from "../components/layout"
import Heading1 from "../components/Heading1"
import SEO from "../components/seo"
import styles from "./contact.module.scss"

const ContactPage = () => (
  <Layout>
    <SEO title="インディーズデベロッパー 大波 誠(おおなみ まこと)のお問い合わせ先" />
    
    <Heading1>お問い合わせ</Heading1>
    <div className={styles.block}>
      <div className={styles.block__description}>
        大波本人へのコミュニケーションやお問い合わせはTwitterにてお受けしています。
        <br></br>
        ご希望の方は<a href="https://twitter.com/z_ohnami" className={styles.block__twitter_link}>@z_ohnami</a>までお気軽にお寄せください。
      </div>
    </div>
  </Layout>
)

export default ContactPage
